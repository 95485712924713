@import url("https://fonts.googleapis.com/css2?family=Mulish&family=Open+Sans:wght@300;400;600&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@400;700&family=Poppins:wght@100;200;300;400;500;600;800&display=swap");
@import url("https://allfont.net/allfont.css?fonts=comic-sans-ms");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  width: 12px;
}
.tab-bar::-webkit-scrollbar {
  width: 12px;
  height: 0px;
}
.poppin {
  font-family: "Poppins", sans-serif;
}
.nue {
  font-family: "Comic Neue", cursive;
}

.hideScroll::-webkit-scrollbar {
  appearance: none;
  width: 0;
  height: 0;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4f46e5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #126315;
}
:root {
  --swiper-navigation-color: white;
}
html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}
/* target the active bullet */
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #b3561b;
  opacity: 1;
}
/* checked input */
input[type="radio"]:checked {
  accent-color: #4f46e5 !important;
}
input[type="checkbox"]:checked {
  accent-color: #4f46e5 !important;
}

/* target all bullets */
.swiper-pagination-bullet {
  background-color: rgb(132, 132, 132);
  opacity: 0.6;
  padding: 7px;
}
.swiper-button-next {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-image: linear-gradient(
      to bottom,
      rgba(246, 239, 239, 0.4),
      rgba(225, 225, 225, 0.4)
    ),
    url("https://res.cloudinary.com/das90kzig/image/upload/v1678528498/react-frontend/arrow-small-right_ixngcd.png");
  background-size: contain;
  background-color: white;
  position: absolute;
  right: 0 !important;
  top: 34% !important;
  z-index: 3000;
}
.swiper-button-prev {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-image: linear-gradient(
      to bottom,
      rgba(246, 239, 239, 0.4),
      rgba(225, 225, 225, 0.4)
    ),
    url("https://res.cloudinary.com/das90kzig/image/upload/v1678528498/react-frontend/arrow-small-left_qifgzs.png");
  background-size: contain;
  background-color: white;
  position: absolute;
  left: 0 !important;
  top: 34% !important;
  z-index: 3000;
}

@media (max-width: 960px) {
  .swiper-button-prev,
  .swiper-button-next {
    width: 22px;
    height: 22px;
    margin-top: 20px;
    display: none !important;
  }
}
.border-btn {
  border: 2px solid #b3561b;
  color: #b3561b;
}
.border-btn:hover {
  background-color: #b3561b;
  color: white;
  transition: 0.3s;
}
.index-10 {
  z-index: 10000;
}
.index-20 {
  z-index: 20000;
}
.index-30 {
  z-index: 20000;
}
.Toastify__toast-container {
  z-index: 30000 !important;
}
.PhoneInputInput {
  outline: none !important;
}
.scroll-pro::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.ps-menu-label {
  font-size: 15px !important;
}
.dropin {
  position: relative;
  animation: drop 0.5s ease-in-out;
}
.dropoff {
  animation: dropoff 0.5s ease-in-out;
}
@keyframes drop {
  0% {
    height: 0px;
  }
  100% {
    height: 400px;
  }
}
@keyframes dropoff {
  0% {
    height: 300px;
  }
  100% {
    height: 0px;
  }
}

/* check mark */
.animation-ctn {
  text-align: center;
}

@-webkit-keyframes checkmark {
  0% {
    stroke-dashoffset: 100px;
  }

  100% {
    stroke-dashoffset: 200px;
  }
}

@-ms-keyframes checkmark {
  0% {
    stroke-dashoffset: 100px;
  }

  100% {
    stroke-dashoffset: 200px;
  }
}

@keyframes checkmark {
  0% {
    stroke-dashoffset: 100px;
  }

  100% {
    stroke-dashoffset: 0px;
  }
}

@-webkit-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 480px;
  }

  100% {
    stroke-dashoffset: 960px;
  }
}

@-ms-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }

  100% {
    stroke-dashoffset: 480px;
  }
}

@keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 480px;
  }

  100% {
    stroke-dashoffset: 960px;
  }
}

@keyframes colored-circle {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}

.inlinesvg .svg svg {
  display: inline;
}

.icon--order-success svg polyline {
  -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
  animation: checkmark 0.25s ease-in-out 0.7s backwards;
}

.icon--order-success svg circle {
  -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
  animation: checkmark-circle 0.6s ease-in-out backwards;
}
.icon--order-success svg circle#colored {
  -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
  animation: colored-circle 0.6s ease-in-out 0.7s backwards;
}
@keyframes moveRight {
  0% {
    transform: translateY(5px);
  }
  /* 25% {
    transform: translateY(2px);
  } */
  50% {
    transform: translateY(-5px); /* Move the image 200px to the right */
  }
  /* 75% {
    transform: translateY(-2px);
  } */
  100% {
    transform: translateY(5px);
  }
}
@layer components {
  /* container settings */
  .box {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 576px) {
    .box {
      width: 540px;
    }
  }
  @media (min-width: 768px) {
    .box {
      width: 720px;
    }
  }
  @media (min-width: 958px) {
    .box {
      width: 880px;
    }
  }
  @media (min-width: 992px) {
    .box {
      width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .box {
      width: 1140px;
    }
  }
  @media (min-width: 1400px) {
    .box {
      width: 1360px;
    }
  }
  @media (min-width: 1600px) {
    .box {
      width: 1460px;
    }
  }
  .section {
    padding: 80px 0px;
  }
  @media (max-width: 1000px) {
    .section {
      padding: 60px 0px;
    }
  }
  .fw-200 {
    font-weight: 200;
  }
  .fw-300 {
    font-weight: 300;
  }
  .fw-400 {
    font-weight: 400;
  }
  .fw-500 {
    font-weight: 500;
  }
  .fw-600 {
    font-weight: 600;
  }
  .fw-700 {
    font-weight: 700;
  }
  .fw-800 {
    font-weight: 800;
  }
  .fw-900 {
    font-weight: 900;
  }
  .fs-400 {
    font-size: 14px;
  }
  .fs-00 {
    font-size: 10px;
  }
  .fs-100 {
    font-size: 11px;
  }
  .fs-200 {
    font-size: 12px;
  }
  .fs-300 {
    font-size: 13px;
  }
  .fs-500 {
    font-size: 15px;
  }
  .fs-600 {
    font-size: 16px;
  }
  .fs-700 {
    font-size: 17px;
  }
  .fs-800 {
    font-size: 18px;
  }
  .circle {
    border-radius: 50%;
  }
  .bg-brown {
    background: rgba(0, 0, 0, 0.05);
  }
  .text-primary {
    color: #4f46e5;
  }
  .bg-primary {
    background: #4f46e5;
  }
  .bg-light {
    background: #f1f1f1;
  }
  .bg-fit {
    background-size: 100% 100%;
  }
  .bg-modal {
    background: rgb(137, 137, 137, 0.5);
  }
  .btn-primary {
    background: #4f46e5;
    color: white;
    font-weight: 500;
    border-radius: 8px 8px 8px 0px;
    box-shadow: 0px 26px 60px 5px rgba(79, 70, 229, 0.31);
  }
  .btn-disabled {
    background: #fff;
    color: #87909ebd;
    font-weight: 500;
    border-radius: 8px 8px 8px 0px;
    box-shadow: 0px 26px 60px 5px rgba(79, 70, 229, 0.31);
  }
  .btn-primary:hover,
  .btn-feel:hover,
  .btn-like:hover {
    background: #302aa6;
    transform: scaleX(1.02);
    transition: 0.6s;
  }
  .btn-feel {
    background: #4f46e5;
    color: white;
    font-weight: 500;
    padding: 15px 30px;
    border-radius: 6px 6px 6px 0px;
  }
  .btn-like {
    background: #4f46e5;
    color: white;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 8px 8px 8px 0px;
    box-shadow: 0px 26px 60px 5px rgba(79, 70, 229, 0.31);
  }
  .shades {
    box-shadow: 0px 4px 16px 15px rgba(0, 15, 152, 0.05);
  }
  .place-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .round-box {
    border-radius: 8px 8px 8px 0px;
  }
  .rotates {
    rotate: z -10deg;
  }
  .dash-shade {
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.07);
  }
  .active {
    background-color: #4f46e5;
    border-radius: 10px;
    display: block;
    padding: 10px;
  }
  .subject-con {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  .rotate-icon {
    rotate: z -45deg;
  }
  .box-shade {
    box-shadow: 0.4000000059604645px 0.4000000059604645px 4px 0px #00000040;
  }
  .types {
    height: 153px;
    border-radius: 17px 17px 0px 17px;
    outline-style: solid;
    outline-color: #4f46e566;
    outline-width: 5px;
    outline-style: dashed;
  }
  .btn-shade {
    box-shadow: 0px 26px 60px 5px rgba(79, 70, 229, 0.31);
  }
  .text-shade {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .image {
    animation: moveRight 2s ease-in-out infinite; /* CSS animation for intermittent movement */
  }
  .gradientbg {
    background: linear-gradient(225deg, #4f46e5 0%, #0e096c 100%);
  }
  .bgImage {
    background: url(../src/pages/schools/sections/howitworks/image/bgimage.png)
      center;
    background-size: cover;
  }
  .bgimagesmall {
    background: url(../src/pages/schools/sections/howitworks/image/bgsmall.png)
      center no-repeat;
    /* background-size: cover; */
  }
  .flippedbgimg {
    background: url(../src/pages/flippedclass/sections/header/images/headerimg.png)
      center no-repeat;
    background-size: cover;
  }
}
